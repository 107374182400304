import { Theme } from 'theme-ui'
import { darken, lighten } from '@theme-ui/color'

const theme: Theme = {
  config: {
    initialColorModeName: 'light',
  },
  colors: {
    text: '#000',
    textInvert: lighten('text', 1),
    background: '#fff',
    backgroundAccent: darken('background', 0.02),
    primary: String(lighten('text', 0.5)),
    secondary: 'red',
    accent: darken('background', 0.8),
    highlight: darken('background', 0.5),
    highlightedItem: '#e8ff50c7',
    muted: darken('#fff', 0.1),
    mutedAccent: darken('#fff', 0.2),
    mutedAccentMore: darken('#fff', 0.3),
    cardBackground: '#fff',
    modes: {
      dark: {
        text: '#fff',
        textInvert: darken('text', 1),
        background: '#000',
        backgroundAccent: lighten('#000', 0.08),
        primary: darken('text', 0.2),
        secondary: 'red',
        accent: lighten('background', 1),
        highlight: lighten('background', 0.5),
        highlightedItem: darken('#e8ff50c7', 0.4),
        muted: lighten('#000', 0.25),
        mutedAccent: lighten('#000', 0.4),
        mutedAccentMore: lighten('#000', 0.35),
        cardBackground: lighten('#000', 0.1),
      },
    },
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: 'body',
      fontWeight: 'body',
    },
    h1: {
      // the style object for each element
      // can reference other values in the theme
      fontFamily: '"Public Sans Variable", sans-serif',
      fontWeight: 100,
      lineHeight: 'headline',
      fontSize: ['3', '4', '5'],
      marginTop: 0,
      marginBottom: 3,
      letterSpacing: '-0.4px',
      color: 'text',
    },
    em: { fontWeight: '500' },
    h2: {
      fontFamily: 'body',
      fontWeight: 'headline',
      maxWidth: '690px',
      marginX: 'auto',
      borderLeftWidth: [0, 0, '1px'],
      borderLeftStyle: 'solid',
      borderLeftColor: 'muted',
      paddingLeft: [0, 0, '8px'],
      marginTop: '20px',
      marginBottom: '0',
      paddingBottom: '10px',
      lineHeight: '30px'
    },
    ol: {
      maxWidth: '690px',
      margin: 'auto',
      borderLeftWidth: [0, 0, '1px'],
      borderLeftStyle: 'solid',
      borderLeftColor: 'muted',
      paddingLeft: [0, 0, '8px'],
      paddingTop:[0, 0, 0],
      listStylePosition: 'inside',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'background',
    },
    ul: {
      maxWidth: '690px',
      margin: 'auto',
      borderLeftWidth: [0, 0, '1px'],
      borderLeftStyle: 'solid',
      borderLeftColor: 'muted',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'background',
      paddingLeft: [0, 0, '8px'],
      listStyle: 'square',
      listStylePosition: 'inside',
    },
    blockquote: {
      backgroundColor: 'blockquoteBg',
      paddingX: '20px',
      paddingY: '20px',
      margin: '0',
      color: 'text',
    },
    hr: {
      color: 'muted',
    },
    p: {
      fontFamily: 'serif',
      lineHeight: '30px',
      fontSize: [20, 20, 20],
      fontWeight: '400',
      maxWidth: ['100%', '100%', '690px'],
      margin: '0 auto',
      borderLeftWidth: [0, 0, '1px'],
      borderLeftStyle: 'solid',
      borderLeftColor: 'muted',
      paddingLeft: [0, 0, '8px'],
    },
    a: {
      ':hover, :focus': {
        color: 'text',
      },
      textDecoration: 'none',
      color: 'text',
    },
    li: {
      fontFamily: 'serif',
      lineHeight: '30px',
      fontSize: [20, 20, 20],
      marginBottom: '20px',
    },
    faded: {
      position: 'fixed',
      top: '0',
      height: '100%',
      width: '100%',
      left: '0',
      backgroundColor: 'rgba(0,0,0,0.8)',
      zIndex: 10000,
      display: 'flex',
    },
    // more styles can be added as needed
  },
  buttons: {
    primary: {
      color: 'background',
      marginRight: 0,
      paddingX: '10px',
      paddingY: '5px',
      fontFamily: 'body',
      cursor: 'pointer',
      // textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '14px',
      letterSpacing: '.5px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  fontSizes: [10, 13, 18, 21, 26, 34, 48],
  fonts: {
    body: '"Public Sans Variable", sans-serif',
    headline: '"Public Sans Variable", sans-serif',
    serif: '"Public Sans Variable", sans-serif',
    mono: '"Public Sans Variable", sans-serif',
  },
  boxes: {
    figure: {
      maxWidth: [null, null, '690px'],
      backgroundColor: 'muted',
      padding: '30px',
      borderRadius: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '60px',
      marginBottom: '60px'
    }
  },
  cards: {
    primary: {
      padding: 0,
      borderRadius: 4,
      // boxShadow: '0 8px 16px -4px rgba(0,0,0,.1), 0 0 8px -3px rgba(0,0,0,.1)',
      marginBottom: '20px',
      backgroundColor: 'muted',
    },
  },
  images: {
    postCardImage: {
      width: ['100%', '200px', '300px'],
      height: ['300px', '150px', '200px'],
      zIndex: '0',
    },
    homePageImage: {
      width: ['100%', '100%', '100%'],
      height: 'auto',
      zIndex: '0',
    },
    relatedImage: {
      width: ['100%', '200px', '300px'],
      height: ['300px', '150px', '200px'],
      zIndex: '0',
    },
    fullScreen: {
      position: 'relative',
      height: '100vh',
      maxHeight: '100%',
      zIndex: '100',
      borderRadius: [4, 4, 4],
    },
  },
  text: {
    headline: {
      fontFamily: 'headline',
      fontSize: '6',
      fontWeight: 'heavy',
      // lineHeight: "0",
      letterSpacing: '0',
      color: 'text',
    },
    heading3: {
      fontFamily: 'serif',
      fontSize: '4',
      fontWeight: '600',
      // lineHeight: "0",
      textDecoration: 'none',
      letterSpacing: '0',
      color: 'text',
    },
    postCardType: {
      fontSize: '16px',
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: '600',
      // letterSpacing: '1px',
      color: 'mutedText',
      display: 'block',
    },
    postCardTitle: {
      fontSize: ['2', '3', '3'],
      fontFamily: 'serif',
      fontWeight: '600',
      color: 'text',
    },
    postCardSubtitle: {
      fontSize: ['14px', '2', '16px'],
      fontFamily: 'body',
      fontWeight: 400,
      color: 'mutedText',
      letterSpacing: '0.3px',
    },
    postCardBottom: {
      fontSize: ['14px', '2', '16px'],
      fontFamily: 'body',
      fontWeight: 400,
      color: 'mutedText',
      letterSpacing: '0.3px',
      height: '100%',
    },
    resultsHeading: {
      // lineHeight: '30px',
      fontFamily: 'body',
      // letterSpacing: '.6px',
      fontWeight: '700',
    },
    resultsItem: {
      fontSize: [16, 16, 17],
      // lineHeight: "35px",
      fontFamily: 'body',
    },
    highlightedItem: {
      textDecorationLine: 'underline',
      textDecorationThickness: '10px',
      textUnderlineOffset: '-8px',
      textDecorationSkipInk: 'none',
      textDecorationColor: 'highlightedItem',
      fontSize: [16, 16, 17],
      // lineHeight: "35px",
      fontFamily: 'body',
    },
    caption: {
      marginTop: '5px',
      display: 'block',
      color: 'primary',
      fontFamily: 'body',
      fontStyle: 'normal',
      fontSize: '13px',
    },
    postSubtitle: {
      fontFamily: 'body',
      fontWeight: '400',
      letterSpacing: '0px',
      fontSize: ['14px', '16px', '16px'],
    },
    postTitle: {
      fontFamily: 'serif',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: ['32px', '40px', '40px'],
      color: 'textInvert',
    },
    postType: {
      fontFamily: 'body',
      fontWeight: '700',
      letterSpacing: '.1em',
      fontSize: ['12px', '16px', '16px'],
      textTransform: 'uppercase',
      color: 'primary',
      lineHeight: '130%',
    },
    raceStatHeading: {
      fontFamily: 'body',
      // color: 'primary',
      fontWeight: '300',
      // letterSpacing: '1px',
      // textTransform: 'uppercase',
      fontSize: [1],
    },
  },
  badges: {
    listSection: {
      fontFamily: 'headline',
      padding: '5px',
      fontSize: ['0', '1', '1'],
      color: 'background',
      textTransform: 'uppercase',
      marginRight: '5px',
    },
  },
  links: {
    menu: {
      fontSize: ['22px', '22px', '22px'],
      // textTransform: 'uppercase',
      fontWeight: '700',
      lineHeight: '1.5',
      margin: '0',
      fontFamily: 'body',
      color: 'text',
      '&:active': {
        color: 'activeMenuLink',
      },
    },
  },
}

export default theme
